import "../styles/index.scss";
import "@shopify/buy-button-js";

import React, { useEffect, useState } from "react";
import { document, window } from "browser-monads";
import { useDrag, useWheel } from "react-use-gesture";
import { ReactFitty } from "react-fitty";

import Div100vh from "react-div-100vh";
import { GoMute } from "react-icons/go";
import Helmet from "react-helmet";
import ReactMarkdown from "react-markdown";
import ReactPlayer from "react-player";
import { graphql } from "gatsby";
import { motion } from "framer-motion";
import NewsletterPopup from "../components/NewsletterPopup/NewsletterPopup.jsx";

const IndexPage = ({ data }) => {
  const dataShop = data.allContentfulBackofficeShop.nodes[0];
  const [showText, setShowtext] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [videoUrl, setVideoUrl] = useState(dataShop.videoDesktop.file.url);
  const [musicPlaying, setMusicPlaying] = useState(false);
  const [newsletterPopup, setNewsletterPopup] = useState(false);
  const isVariants = dataShop.isVariants && dataShop.statut === "ADD TO CART";

  let client = window.ShopifyBuy.buildClient({
    domain: "bigwax.io",
    storefrontAccessToken: "31682f9bb9f4efdfdcfd96fb08af4c27",
  });

  const buyButtonOptions = {
    product: {
      iframe: false,
      styles: {
        product: {
          "@media (min-width: 601px)": {
            "max-width": "calc(25% - 20px)",
            "margin-left": "20px",
            "margin-bottom": "50px",
          },
        },
        title: {
          "font-weight": "normal",
          color: "#000000",
        },
        button: {
          "font-family": "Arvo, serif",
          color: "#000000",
          ":hover": {
            "background-color": "#ffffff",
          },
          "background-color": "#ffffff",
          ":focus": {
            "background-color": "#ffffff",
          },
          "border-radius": "0px",
          "padding-left": "30px",
          "padding-right": "30px",
        },
        price: {
          color: "#000000",
        },
        compareAt: {
          color: "#000000",
        },
        unitPrice: {
          color: "#000000",
        },
        description: {
          color: "#000000",
        },
      },
      contents: {
        img: false,
        title: false,
        price: false,
      },
      text: {
        button: "BUY NOW",
      },
      googleFonts: ["Arvo"],
    },
    productSet: {
      styles: {
        products: {
          "@media (min-width: 601px)": {
            "margin-left": "-20px",
          },
        },
      },
    },
    modalProduct: {
      contents: {
        img: false,
        imgWithCarousel: true,
      },
      styles: {
        product: {
          "@media (min-width: 601px)": {
            "max-width": "100%",
            "margin-left": "0px",
            "margin-bottom": "0px",
          },
        },
        button: {
          "font-family": "Arvo, serif",
          ":hover": {
            "background-color": "#000000",
          },
          "background-color": "#000000",
          ":focus": {
            "background-color": "#000000",
          },
          "border-radius": "0px",
          "padding-left": "30px",
          "padding-right": "30px",
        },
        title: {
          "font-family": "Helvetica Neue, sans-serif",
          "font-weight": "normal",
          "font-size": "28px",
          color: "#000000",
        },
        price: {
          "font-family": "Helvetica Neue, sans-serif",
          "font-weight": "normal",
          "font-size": "24px",
          color: "#000000",
        },
        compareAt: {
          "font-family": "Helvetica Neue, sans-serif",
          "font-weight": "normal",
          "font-size": "20.4px",
          color: "#000000",
        },
        unitPrice: {
          "font-family": "Helvetica Neue, sans-serif",
          "font-weight": "normal",
          "font-size": "20.4px",
          color: "#000000",
        },
        description: {
          "font-family": "Helvetica Neue, sans-serif",
          "font-weight": "normal",
          "font-size": "16px",
          color: "#000000",
        },
      },
      googleFonts: ["Arvo"],
      text: {
        button: "Add to cart",
      },
    },
    modal: {
      styles: {
        modal: {
          "background-color": "#fffdfd",
        },
      },
    },
    option: {
      styles: {
        label: {
          "font-family": "Arvo, serif",
          color: "#000000",
        },
        select: {
          "font-family": "Arvo, serif",
        },
      },
      googleFonts: ["Arvo"],
    },
    cart: {
      styles: {
        button: {
          "font-family": "Arvo, serif",
          ":hover": {
            "background-color": "#000000",
          },
          "background-color": "#000000",
          ":focus": {
            "background-color": "#000000",
          },
          "border-radius": "0px",
        },
        title: {
          color: "#000000",
        },
        header: {
          color: "#000000",
        },
        lineItems: {
          color: "#000000",
        },
        subtotalText: {
          color: "#000000",
        },
        subtotal: {
          color: "#000000",
        },
        notice: {
          color: "#000000",
        },
        currency: {
          color: "#000000",
        },
        close: {
          color: "#000000",
          ":hover": {
            color: "#000000",
          },
        },
        empty: {
          color: "#000000",
        },
        noteDescription: {
          color: "#000000",
        },
        discountText: {
          color: "#000000",
        },
        discountIcon: {
          fill: "#000000",
        },
        discountAmount: {
          color: "#000000",
        },
      },
      text: {
        total: "Total",
        notice: "",
        button: "PAY",
      },
      contents: {
        note: true,
      },
      popup: false,
      googleFonts: ["Arvo"],
    },
    toggle: {
      styles: {
        toggle: {
          "font-family": "Arvo, serif",
          "background-color": "#000000",
          ":hover": {
            "background-color": "#000000",
          },
          ":focus": {
            "background-color": "#000000",
          },
        },
      },
      googleFonts: ["Arvo"],
    },
    lineItem: {
      styles: {
        variantTitle: {
          color: "#000000",
        },
        title: {
          color: "#000000",
        },
        price: {
          color: "#000000",
        },
        fullPrice: {
          color: "#000000",
        },
        discount: {
          color: "#000000",
        },
        discountIcon: {
          fill: "#000000",
        },
        quantity: {
          color: "#000000",
        },
        quantityIncrement: {
          color: "#000000",
          "border-color": "#000000",
        },
        quantityDecrement: {
          color: "#000000",
          "border-color": "#000000",
        },
        quantityInput: {
          color: "#000000",
          "border-color": "#000000",
        },
      },
    },
  };

  //CRÉE LE COMPOSANT SHOPIFY
  let ui = window.ShopifyBuy.UI.init(client);
  useEffect(() => {
    if (dataShop.statut === "ADD TO CART") {
      ui.createComponent("product", {
        id: dataShop.bigwaxId,
        node: document.getElementById("product-component"),
        moneyFormat: "%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D",
        options: buyButtonOptions,
      });
    }

    if (!isVariants || dataShop.statut !== "ADD TO CART") {
      const variantsButton = document.getElementById("product-component");
      variantsButton.style.height = "0px";
    }
  }, [ui, dataShop.bigwaxId]);

  //useDrag => Détecte le scroll sur mobile
  //useWheel => Détecte le scroll sur desktop
  const doIShowText = (delta) => {
    if (delta > 7) {
      setShowtext(false);
    }
    if (delta < -7) {
      setShowtext(true);
    }
  };

  // const bindDrag = useDrag((e) => doIShowText(-e.delta[1]), {});
  const bindWheel = useWheel((e) => doIShowText(e.delta[1]), {});

  //DÉTECTE ET ENREGISTRE LA LARGEUR DE LA FENÊTRE
  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
    return () =>
      window.removeEventListener("resize", () =>
        setWindowWidth(window.innerWidth)
      );
  }, []);

  //Change la source de la vidéo selon la largeur de l'écran
  useEffect(() => {
    windowWidth < 800
      ? setVideoUrl(dataShop.videoMobile.file.url)
      : setVideoUrl(dataShop.videoDesktop.file.url);
  }, [
    windowWidth,
    dataShop.videoMobile.file.url,
    dataShop.videoDesktop.file.url,
  ]);

  return (
    <>
      <Helmet title={"Étienne de Crécy"} htmlAttributes={{ lang: "fr" }}>
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        {/* MAILJET */}
        <script
          type="text/javascript"
          src="https://app.mailjet.com/pas-nc-pop-in-v1.js"
        ></script>
        {/* MAILJET */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-T2M7T7TBNX"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-T2M7T7TBNX');
          `}
        </script>
      </Helmet>
      {dataShop.mp3 && (
        <ReactPlayer
          style={{ display: "none" }}
          playing={musicPlaying}
          loop={true}
          url={dataShop.mp3.file.url}
        />
      )}
      <div
        className="music-toggle"
        onClick={() => {
          setMusicPlaying(!musicPlaying);
        }}
      >
        {musicPlaying ? (
          <i className="gg-loadbar-sound" style={{ color: "white" }}></i>
        ) : (
          <GoMute fill="white" />
        )}
      </div>
      <NewsletterPopup
        visible={newsletterPopup}
        onClose={(e) => setNewsletterPopup(e)}
      />
      <Div100vh
        // {...bindDrag()}
        {...bindWheel()}
        className={showText ? "main-container cursorPointer" : "main-container"}
      >
        {/* -------VIDEO */}
        <div className="videoContainer">
          <video
            muted={!dataShop.mp3 && musicPlaying ? false : true}
            loop
            autoPlay
            playsInline
            key={videoUrl}
          >
            <source src={videoUrl} type="video/mp4" />
            <track kind="captions" />
            Your browser does not support the video tag.
          </video>
        </div>
        {/* -------VIDEO */}
        {/* -------TEXTES */}
        <motion.span
          className="homeTextSpan"
          initial={{ opacity: 1 }}
          animate={{ opacity: showText ? 1 : 0 }}
        >
          <h1 className="punchline">
            <ReactFitty
              className="punchlineFitty"
              maxSize={260}
              key={windowWidth + "punchline"}
            >
              {dataShop.punchline}
            </ReactFitty>
          </h1>
          <h2 className="description">
            <ReactMarkdown
              source={dataShop.description.description}
              className="reactMarkDown"
            />
          </h2>
          <div id="product-component"></div>
          <h1
            className="callToAction"
            style={{
              transform: isVariants ? "translateY(-60px)" : "translateY(0px)",
            }}
            onClick={(e) => {
              if (!showText) {
                setShowtext(true);
              }
              if (
                showText &&
                typeof window !== "undefined" &&
                dataShop.statut === "ADD TO CART"
              ) {
                e.stopPropagation();
                ui.toggleCart();
                // document.getElementsByClassName("shopify-buy__btn")[0].click();
                document.querySelector(".shopify-buy__btn").click();
              }
              if (
                showText &&
                typeof window !== "undefined" &&
                dataShop.statut === "NEWSLETTER"
              ) {
                setNewsletterPopup(true);
              }
            }}
          >
            <ReactFitty
              className="callToActionFitty"
              key={windowWidth + "cta"}
              maxSize={50}
            >
              {dataShop.callToAction}
            </ReactFitty>
          </h1>
        </motion.span>
        {/* -------TEXTES */}
      </Div100vh>
    </>
  );
};

export const query = graphql`
  query DeCrecyShopQuery {
    allContentfulBackofficeShop {
      nodes {
        punchline
        callToAction
        statut
        isVariants
        titre
        bigwaxId
        description {
          description
        }
        videoMobile {
          file {
            url
          }
        }
        videoDesktop {
          file {
            url
          }
        }
        mp3 {
          file {
            url
          }
        }
      }
    }
  }
`;

export default IndexPage;
